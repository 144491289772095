var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "customer-apply-dialog",
      attrs: {
        title: _vm.applyTitle,
        visible: _vm.dialogVisible,
        width: "818px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.tableData.length > 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "customer-list",
            },
            _vm._l(_vm.pageTableData, function (item, index) {
              return _c("div", { key: index, staticClass: "customer-item" }, [
                _c("div", { staticClass: "customer-main" }, [
                  _c("div", { staticClass: "customer-item-left" }, [
                    _c("div", { staticClass: "name-line" }, [
                      _c(
                        "span",
                        {
                          staticClass: "name",
                          attrs: { title: item.name },
                          on: {
                            click: function ($event) {
                              return _vm.toggleDetail(item)
                            },
                          },
                        },
                        [
                          _vm._v(_vm._s(item.name)),
                          _c("i", { staticClass: "el-icon-arrow-down" }),
                        ]
                      ),
                      item.fullName
                        ? _c(
                            "span",
                            {
                              staticClass: "full-name",
                              attrs: { title: item.fullName },
                            },
                            [_vm._v(_vm._s(item.fullName))]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "industry-line" }, [
                      _c(
                        "span",
                        {
                          staticClass: "industry",
                          attrs: { title: item.industryName },
                        },
                        [_vm._v(_vm._s(item.industryName))]
                      ),
                      _c("i"),
                      _c(
                        "span",
                        {
                          staticClass: "domain",
                          attrs: {
                            title: `${item.domainParentName}-${item.domainName}`,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(item.domainParentName) +
                              "-" +
                              _vm._s(item.domainName)
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "tag-line" }, [
                      item.importantLevel
                        ? _c("span", [_vm._v(_vm._s(item.importantLevel))])
                        : _vm._e(),
                      item.businessNature
                        ? _c("span", [_vm._v(_vm._s(item.businessNature))])
                        : _vm._e(),
                      item.developerCount
                        ? _c("span", [
                            _vm._v("研发" + _vm._s(item.developerCount) + "人"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "customer-item-right" }, [
                    _vm.isCustomer
                      ? _c(
                          "div",
                          { staticClass: "btn-line" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.pass(item.id)
                                  },
                                },
                              },
                              [_vm._v("通过")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.reject(item.id)
                                  },
                                },
                              },
                              [_vm._v("拒绝")]
                            ),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "btn-line" }, [
                          item.status == 0
                            ? _c("span", { staticClass: "status_created" }, [
                                _vm._v("待审核"),
                              ])
                            : _vm._e(),
                          item.status == 1
                            ? _c("span", { staticClass: "status_pass" }, [
                                _vm._v("审核通过"),
                              ])
                            : _vm._e(),
                          item.status == 2
                            ? _c(
                                "span",
                                { staticClass: "status_fail" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: item.rejectReason,
                                        placement: "bottom",
                                        effect: "light",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-warning-outline",
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                                审核失败\n                            "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                    _c("div", { staticClass: "bd-line" }, [
                      item.customerApplicationBDs &&
                      item.customerApplicationBDs.length > 0
                        ? _c(
                            "span",
                            {
                              staticClass: "bd",
                              attrs: {
                                title: item.customerApplicationBDs
                                  .map((bd) => bd.userName)
                                  .join(","),
                              },
                            },
                            [
                              _vm._v(
                                "BD：" +
                                  _vm._s(
                                    item.customerApplicationBDs
                                      .map((bd) => bd.userName)
                                      .join(",")
                                  )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass: "applyer",
                          attrs: { title: item.ownerName },
                        },
                        [_vm._v("申请人：" + _vm._s(item.ownerName))]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.isExpand,
                        expression: "item.isExpand",
                      },
                    ],
                    staticClass: "customer-detail",
                  },
                  [
                    _c("div", { staticClass: "detail-name-line" }, [
                      _c(
                        "span",
                        { staticClass: "name", attrs: { title: item.name } },
                        [_vm._v(_vm._s(item.name))]
                      ),
                      item.address
                        ? _c(
                            "span",
                            {
                              staticClass: "location",
                              attrs: { title: item.address },
                            },
                            [_vm._v(_vm._s(item.address))]
                          )
                        : _vm._e(),
                      item.address && item.companyScale ? _c("i") : _vm._e(),
                      item.companyScale
                        ? _c("span", { staticClass: "scale" }, [
                            _vm._v(
                              _vm._s(_vm.companyScaleMap[item.companyScale])
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    item.webSite
                      ? _c(
                          "div",
                          {
                            staticClass: "detail-site",
                            attrs: { title: item.webSite },
                          },
                          [_vm._v(_vm._s(item.webSite))]
                        )
                      : _vm._e(),
                    item.description
                      ? _c(
                          "div",
                          {
                            staticClass: "detail-desc",
                            attrs: { title: item.description },
                          },
                          [_vm._v(_vm._s(item.description))]
                        )
                      : _vm._e(),
                  ]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.tableData.length == 0
        ? _c("div", { staticClass: "customer-empty" }, [
            _vm._v(_vm._s(_vm.loading ? "" : "暂无数据")),
          ])
        : _vm._e(),
      _vm.tableData.length > 0
        ? _c("el-pagination", {
            staticClass: "customer-apply-pagination",
            attrs: {
              "current-page": _vm.pager.current,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.pager.size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pager.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "拒绝理由",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "append-to-body": true,
            "before-close": _vm.handleRejectClose,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "rejectForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "reason" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 6 },
                      placeholder: "请输入内容",
                      maxlength: "200",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reason", $$v)
                      },
                      expression: "form.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleRejectClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.rejectLoading,
                      expression: "rejectLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handleReject },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }