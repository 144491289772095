<template>
    <el-dialog
        class="customer-apply-dialog"
        :title="applyTitle"
        :visible.sync="dialogVisible"
        width="818px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose">

        <div v-if="tableData.length > 0" class="customer-list" v-loading="loading">
            <div class="customer-item" v-for="(item, index) in pageTableData" :key="index">
                <div class="customer-main">
                    <div class="customer-item-left">
                        <div class="name-line">
                            <span class="name" @click="toggleDetail(item)" :title="item.name">{{item.name}}<i class="el-icon-arrow-down"></i></span>
                            <span v-if="item.fullName" class="full-name" :title="item.fullName">{{item.fullName}}</span>
                        </div>
                        <div class="industry-line">
                            <span class="industry" :title="item.industryName">{{item.industryName}}</span>
                            <i></i>
                            <span class="domain" :title="`${item.domainParentName}-${item.domainName}`">{{item.domainParentName}}-{{item.domainName}}</span>
                        </div>
                        <div class="tag-line">
                            <span v-if="item.importantLevel">{{item.importantLevel}}</span>
                            <span v-if="item.businessNature">{{item.businessNature}}</span>
                            <span v-if="item.developerCount">研发{{item.developerCount}}人</span>
                        </div>
                    </div>
                    <div class="customer-item-right">
                        <div class="btn-line" v-if="isCustomer">
                            <el-button type="primary" @click="pass(item.id)">通过</el-button>
                            <el-button type="danger" @click="reject(item.id)">拒绝</el-button>
                        </div>
                        <div class="btn-line" v-else>
                            <span class="status_created" v-if="item.status == 0">待审核</span>
                            <span class="status_pass" v-if="item.status == 1">审核通过</span>
                            <span class="status_fail" v-if="item.status == 2">
                                <el-tooltip 
                                    :content="item.rejectReason" 
                                    placement="bottom" 
                                    effect="light">
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                                审核失败
                            </span>
                        </div>
                        <div class="bd-line">
                            <span class="bd" 
                                v-if="item.customerApplicationBDs && item.customerApplicationBDs.length > 0"
                                :title="item.customerApplicationBDs.map(bd=>bd.userName).join(',')"
                            >BD：{{item.customerApplicationBDs.map(bd=>bd.userName).join(',')}}</span>
                            <span class="applyer" :title="item.ownerName">申请人：{{item.ownerName}}</span>
                        </div>
                    </div>
                </div>
                <div class="customer-detail" v-show="item.isExpand">
                    <div class="detail-name-line">
                        <span class="name" :title="item.name">{{item.name}}</span>
                        <span class="location" v-if="item.address" :title="item.address">{{item.address}}</span>
                        <!-- <span class="location">公司地址：湖南省长沙市雨花区南二段B3栋4楼  </span> -->
                        <i v-if="item.address && item.companyScale"></i>
                        <span class="scale" v-if="item.companyScale">{{companyScaleMap[item.companyScale]}}</span>
                    </div>
                    <div class="detail-site" v-if="item.webSite" :title="item.webSite">{{item.webSite}}</div>
                    <!-- <div class="detail-site">官网：https://www.beyondsoft.com/</div> -->
                    <div class="detail-desc" v-if="item.description" :title="item.description">{{item.description}}</div>
                    <!-- <div class="detail-desc">博彦科技（深交所上市公司, 股票代码002649）是亚太领先的全方位IT服务及行业解决方案提供商，具备全球范围内的交付能力。博彦科技创立于1995年，员工20000多人, 是中国IT行业的先行者和领军企业，拥有完善、成熟的管理和开…</div> -->
                </div>
            </div>
        </div>

        <div v-if="tableData.length == 0" class="customer-empty">{{ loading ? '' : '暂无数据'}}</div>

        <el-pagination
            :current-page="pager.current"
            :page-sizes="[10, 20, 50]"
            :page-size="pager.size"
            class="customer-apply-pagination"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pager.total"
            v-if="tableData.length > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>

        <!-- <el-table
            :data="tableData"
            v-loading="loading"
            stripe
            style="width: 100%">
            <el-table-column
                prop="fullName"
                label="客户名称1"
                width="100"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="name"
                label="客户简称"
                width="100"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="industryName"
                label="所属行业"
                width="120"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="address"
                label="所属领域"
                width="100"
                show-overflow-tooltip
                v-if="isCFUser">
                <template slot-scope="{ row }">
                    {{row.domainParentName}}-{{row.domainName}}
                </template>
            </el-table-column>
            <el-table-column
                prop="companyScale"
                label="公司人数"
                width="100"
                :formatter="companyScaleFormatter"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="address"
                label="公司地址"
                width="140"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="webSite"
                label="公司网站"
                width="180"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="description"
                label="公司介绍"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="customerApplicationBDs"
                label="BD"
                width="100"
                show-overflow-tooltip>
                <template slot-scope="{ row }">
                    {{row.customerApplicationBDs.map(item=>item.userName).join(',')}}
                </template>
            </el-table-column>
            <el-table-column
                prop="ownerName"
                label="申请人"
                width="100"
                show-overflow-tooltip>
            </el-table-column>
            
            <template v-if="isCustomer">
                <el-table-column
                    label="操作"
                    width="100">
                    <template slot-scope="{ row }">
                        <a class="pass" href="javascript:;" @click="pass(row.id)">通过</a>
                        <a class="reject" href="javascript:;" @click="reject(row.id)">拒绝</a>
                    </template>
                </el-table-column>
            </template>
            <template v-else>
                <el-table-column
                    prop="status"
                    label="状态"
                    width="100">
                    <template slot-scope="{ row }">
                        <span class="status_created" v-if="row.status == 0">待审核</span>
                        <span class="status_pass" v-if="row.status == 1">审核通过</span>
                        <span class="status_fail" v-if="row.status == 2">
                            <el-tooltip 
                                :content="row.rejectReason" 
                                placement="bottom" 
                                effect="light">
                                <i class="el-icon-warning-outline"></i>
                            </el-tooltip>
                            审核失败
                        </span>
                    </template>
                </el-table-column>
            </template>
        </el-table> -->

        <el-dialog 
            title="拒绝理由" 
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :append-to-body="true"
            :before-close="handleRejectClose"
            width="500px"
            >
            <el-form :model="form" :rules="rules" ref="rejectForm">
                <el-form-item label="" prop="reason">
                    <!-- <el-input v-model="form.reason" autocomplete="off"></el-input> -->
<!-- :label-width="formLabelWidth" -->
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 6}"
                        placeholder="请输入内容"
                        maxlength="200"
                        show-word-limit
                        v-model="form.reason"
                        >
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleRejectClose">取消</el-button>
                <el-button type="primary" @click="handleReject" v-loading="rejectLoading">确定</el-button>
            </div>
        </el-dialog>
    </el-dialog>
</template>

<script>
    // import hotCityMap from '#/js/config/hotCity.json';
    import customerService from '#/js/service/customerService.js';
    import companyScaleMap from '#/js/config/companyScale.json';
    const statusMap = ["创建", "通过", "拒绝", "取消"];

    export default {
        name: "customerApplyDialog",
        data() {
            return {
                dialogVisible: false,
                // applyTitle: '客户创建审批',
                tableData: [],
                pageTableData: [],
                loading: false,
                rejectLoading: false,
                companyScaleMap: companyScaleMap,
                pager: {
                    current: 1,
                    size: 10,
                    total: 0
                },

                dialogFormVisible: false,
                currentApplyId: '',
                form: {
                    reason: '',
                },
                rules: {
                    reason: [
                        { required: true, message: '请填写拒绝原因', trigger: 'blur' },
                    ],
                },
            }
        },
        watch: {},
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            isCustomer() {
                // return true;
                return this.userInfo.privilegeCodeList?.includes('CustomerCreateAudit');
            },
            isCFUser() {
                return this.userInfo.isCFUser;
            },
            applyTitle() {
                return this.isCustomer ? '客户创建审批': '客户创建申请';
            }
        },
        methods: {
            show() {
                this.dialogVisible = true;
                this.fetchData();
            },
            toggleDetail(item) {
                item.isExpand = !item.isExpand;
            },
            handleSizeChange(val){
                this.pager.size = val;
                this.pager.current = 1;
                this.pageTableData = this.tableData.slice((this.pager.current - 1) * this.pager.size, this.pager.current * this.pager.size);
                this.$nextTick(() => {
                    this.scrollListTop();
                })
            },
            handleCurrentChange(current) {
                this.pager.current = current;
                this.pageTableData = this.tableData.slice((this.pager.current - 1) * this.pager.size, this.pager.current * this.pager.size);
                this.scrollListTop();
            },
            scrollListTop() {
                if(document.querySelector(".customer-apply-dialog .el-dialog__body")) {
                    document.querySelector('.customer-apply-dialog .el-dialog__body').scrollTop = 0;
                }
            },
            // 判断请求不同的接口
            getRequest() {
                if(this.isCustomer) {
                    return customerService.getCustomerAuditList();
                } else {
                    return customerService.getMyCustomerApply();
                }
            },
            fetchData() {
                this.loading = true;
                this.getRequest().then(res => {
                    let list = res || [];
                    list.forEach(item => {
                        item.isExpand = false;
                    })
                    this.tableData = list;
                    this.pageTableData = list.slice((this.pager.current - 1) * this.pager.size, this.pager.current * this.pager.size);
                    this.pager.total = list.length;
                }).finally(() => {
                    this.loading = false;
                });
            },
            handleClose() {
                this.dialogVisible = false;
            },
            handleRejectClose() {
                if (this.rejectLoading) {
                    return false;
                }
                this.dialogFormVisible = false;
                this.$refs['rejectForm'].clearValidate();
                this.$refs['rejectForm'].resetFields();
            },
            pass(id) {
                this.$confirm('此操作将确定通过申请, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                    closeOnPressEscape: false
                }).then(() => {
                    this.loading = true;
                    const params = {
                        applicationId: id,
                        status: 1,
                        rejectReason: null,
                    };
                    customerService.customerApplyAudit(params).then(res => {
                        shortTips('提交成功！');
                        this.fetchData();
                        this.$emit('finish-callback', true);
                    }).finally(() => {
                        this.loading = false;
                    });
                });
            },
            reject(id) {
                this.dialogFormVisible = true;
                this.currentApplyId = id;
            },
            handleReject() {
                this.$refs['rejectForm'].validate((valid) => {
                    if (valid) {
                        if (this.rejectLoading) {
                            return false;
                        }

                        this.rejectLoading = true;
                        const params = {
                            applicationId: this.currentApplyId,
                            status: 2,
                            rejectReason: this.form.reason,
                        };
                        customerService.customerApplyAudit(params).then(res => {
                            shortTips('提交成功！');
                            this.fetchData();
                            this.$emit('finish-callback', false);
                        }).finally(() => {
                            this.rejectLoading = false;
                            this.handleRejectClose();
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            statusFormatter(row, column, cellValue, index) {
                 //  [Display(Name = "创建")]
                //     Created = 0,
                //     [Display(Name = "通过")]
                //     Passed = 1,
                //     [Display(Name = "拒绝")]
                //     Rejected = 2,
                //     [Display(Name = "取消")]
                //     Canceled = 3

                // 待审核/审核通过/审核失败
                const customerApplyStatus = ["待审核", "审核通过", "审核失败"];
                return customerApplyStatus[cellValue];
            },
            companyScaleFormatter(row, column, cellValue, index) {
                return companyScaleMap[cellValue];
            }
        }
    }
</script>

<style lang="scss">
.customer-apply-dialog .el-dialog__body {
    max-height: 630px;
    overflow-y: auto;
}
.pass{
    color: #38BC9D;
    margin-right: 13px;
    &:hover{
        color: #38BC9D;
    }
}
.reject{
    color: #FF6564;
    &:hover{
        color: #FF6564;
    }
}

.status_created{
    color: #F39800;
}
.status_pass{
    color: #38BC9D;
}
.status_fail{
    color: #FF6564;
}
</style>

<style lang="scss" scoped>
.customer-list {
    min-height: 100px;
    margin-top: -6px;

    .customer-item {
        display: flex;
        flex-direction: column;
        padding-bottom: 6px;
        border-bottom: 1px solid rgba(235,235,235,0.92);

        &:last-child {
            border-bottom: 0 none;
        }

        .customer-main {
            display: flex;
            padding-top: 12px;
        }

        &-left {
            flex: 2;
            max-width: 60%;

            .name-line {
                display: flex;
                align-items: center;
                height: 22px;
                margin-bottom: 6px;

                .name {
                    position: relative;
                    max-width: 40%;
                    padding-right: 20px;
                    font-size: 16px;
                    color: #333;
                    font-weight: 600;
                    cursor: pointer;

                    i {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 16px;
                        height: 16px;
                        margin-top: -8px;
                    }
                }

                .full-name {
                    max-width: 60%;
                    margin-left: 10px;
                    color: #333;
                }

                .name, .full-name {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .industry-line {
                display: flex;
                align-items: center;
                height: 20px;
                margin-bottom: 6px;

                i {
                    display: inline-block;
                    width: 1px;
                    height: 12px;
                    margin: 0 8px;
                    background-color: #DCDCDC;
                }

                .industry, .domain {
                    display: inline-block;
                    max-width: 50%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .tag-line {
                margin-bottom: 10px;

                span {
                    display: inline-block;
                    min-width: 28px;
                    height: 26px;
                    line-height: 26px;
                    padding: 0 10px;
                    margin-right: 5px;
                    color: #999;
                    background: #F6F6F6;
                    border-radius: 3px;
                }
            }
        }

        &-right {
            flex: 1;
            padding-left: 10px;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;

            .btn-line {
                margin-bottom: 10px;
            }

            .bd-line {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-wrap: wrap;
                line-height: 20px;
                color: #666;

                .bd, .applyer {
                    display: inline-block;
                    // max-width: 50%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .applyer {
                    margin-left: 16px;
                }
            }
        }

        .customer-detail {
            padding: 16px;
            background-color: #F6F6F6;
            border-radius: 3px;

            .detail-name-line {
                display: flex;
                align-items: center;
                height: 20px;
                margin-bottom: 2px;

                .name {
                    color: #333;
                    font-weight: 600;
                    margin-right: 16px;
                }

                i {
                    display: inline-block;
                    width: 1px;
                    height: 12px;
                    margin: 0 8px;
                    background-color: #DCDCDC;
                }

                .name, .location {
                    display: inline-block;
                    max-width: 50%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .scale {
                    flex-shrink: 0;
                }
            }
            .detail-site {
                width: 100%;
                height: 20px;
                line-height: 20px;
                color: #999;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .detail-desc {
                line-height: 20px;
                color: #999;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}

.customer-empty {
    text-align: center;
    padding: 20px 0;
}

.customer-apply-pagination.el-pagination {
    text-align: center;
    padding-top: 14px;
    padding-bottom: 4px;

    button.btn-prev, button.btn-next {
        background-color: transparent;
    }

    button:disabled {
        background-color: transparent;
    }

    .el-pager li {
        background-color: transparent;
    }
}
</style>
