var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-over-tooltip-components" },
    [
      _c(
        "el-tooltip",
        {
          attrs: {
            effect: _vm.effect,
            disabled: _vm.isDisabledTooltip,
            content: _vm.content,
            placement: _vm.placement,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "ellipsis",
              class: _vm.className,
              on: {
                mouseover: function ($event) {
                  return _vm.onMouseOver(_vm.refName)
                },
              },
            },
            [_c("span", { ref: _vm.refName }, [_vm._v(_vm._s(_vm.content))])]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }