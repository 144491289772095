<template>
    <el-dialog
    title="删除客户"
    :visible.sync="dialogVisible"
    width="350px"
    v-loading="loading"
    :before-close="cancelHandle"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
        <p>确定要删除客户公司 "{{customerName}}" 吗?</p>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import customerListService from '#/js/service/customerListService.js';
import emitter from '#/js/mixins/emitter';
export default {
    components:{
    },
    props: {
    },
    mixins: [emitter],
    data(){
        return{
            loading: false,
            dialogVisible: false,
            customerName: '',
            customerId: ''
        }
    },
    computed:{
        token(){
            return this.$store.state.verificationToken.verificationToken;
        }
    },
    mounted(){
        
    },
    methods: {
        show(json) {
            this.customerName = json.name;
            this.customerId = json.id;
            this.dialogVisible = true;
        },
        cancelHandle() {
            this.dialogVisible = false;
        },
        confirmHandle() {
            this.loading = true;
            customerListService.deleteCustomer(this.customerId)
            .then(res => {
                this.loading = false;
                shortTips('操作成功！');
                this.dialogVisible = false;
                this.dispatch('CustomerWrapperList', 'refreshList');
            }).catch(err =>{
                this.loading = false;
            })
        }
    }
};

</script>

<style lang="scss" scoped>
.warn-msg {
    margin-top: 10px;

    .icon-warning-circle {
        color: #ff493c;
    }

    >span {
        color: #a94442;
    }
}
</style>


