var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-list" },
    [
      _c(
        "div",
        { staticClass: "more-params clearfix" },
        [
          _c("div", { staticClass: "customer-total" }, [
            _vm._v("\n            共"),
            _c("span", [_vm._v(" " + _vm._s(_vm.totalCount) + " ")]),
            _vm._v("家客户\n        "),
          ]),
          _c(
            "el-select",
            {
              staticClass: "more-params-select",
              on: { change: _vm.handleSortChange },
              model: {
                value: _vm.sort,
                callback: function ($$v) {
                  _vm.sort = $$v
                },
                expression: "sort",
              },
            },
            [
              _c("el-option", {
                attrs: { label: "创建时间排序", value: 0, selected: "" },
              }),
              _c("el-option", { attrs: { label: "订单数量排序", value: 1 } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "ul",
        [
          _vm._m(0),
          _vm.list.length == 0
            ? _c("li", { staticClass: "customer-list-item-empty" }, [
                _vm._v("暂无数据"),
              ])
            : _vm._e(),
          _vm.list.length > 0
            ? _vm._l(_vm.list, function (customer) {
                return _c(
                  "li",
                  {
                    key: customer.customerId,
                    staticClass: "customer-list-item",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "col-5 customer-name",
                        attrs: {
                          href: `/Headhunting/MyCompany.html#/Customer/${customer.customerId}`,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(customer.customerName))]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6 job-col" },
                      [
                        _c("span", { staticClass: "job-count" }, [
                          _vm._v(_vm._s(customer.jobCount)),
                        ]),
                        _c("span", { staticClass: "vline" }, [_vm._v(" | ")]),
                        _c(
                          "el-tooltip",
                          { attrs: { content: "招聘中", placement: "bottom" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "job-recruiting",
                                attrs: { "data-original-title": "", title: "" },
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-font icon-remen",
                                }),
                                _c("span", [
                                  _vm._v(_vm._s(customer.jobRecruitingCount)),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          { attrs: { content: "已暂停", placement: "bottom" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "job-paused",
                                attrs: { "data-original-title": "", title: "" },
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-font icon-zanting",
                                }),
                                _c("span", [
                                  _vm._v(_vm._s(customer.jobPausedCount)),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          { attrs: { content: "已完成", placement: "bottom" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "job-finished",
                                attrs: { "data-original-title": "", title: "" },
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-font icon-finish",
                                }),
                                _c("span", [
                                  _vm._v(_vm._s(customer.jobFinishedCount)),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-4 structure-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "department-link",
                          attrs: {
                            href: `/Headhunting/MyCompany.html#/Customer/${customer.customerId}/department`,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        查看\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-5 creator-info creator-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "customer-creator" },
                          [
                            customer && customer.avatarId
                              ? _c("avatar", {
                                  staticClass: "users-avatar",
                                  attrs: {
                                    src: _vm.formatAvatarUrl(
                                      `/Data/Avatar/${customer.avatarId}`
                                    ),
                                    "user-id": customer.userId,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "creator-info-name",
                            attrs: { title: customer.userName },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(customer.userName) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm.isCustomerManage
                      ? _c(
                          "div",
                          { staticClass: "col-2 text-center show-more" },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  "popper-class": "show-more-popover",
                                  placement: "bottom",
                                  width: "184",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("ul", [
                                  _c(
                                    "li",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.mergeToAnotherCustomer(
                                            customer
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("合并到另一客户")]
                                  ),
                                  customer.canDelete
                                    ? _c(
                                        "li",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(
                                                customer.customerName,
                                                customer.customerId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("i", {
                                  staticClass: "icon-font icon-set",
                                  attrs: {
                                    slot: "reference",
                                    "data-original-title": "",
                                    title: "",
                                  },
                                  slot: "reference",
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              })
            : _vm._e(),
        ],
        2
      ),
      _c("customer-merge-dialog", {
        ref: "cusMergeDialog",
        on: {
          "merge-done": function ($event) {
            return _vm.$emit("refresh")
          },
        },
      }),
      _c("delete-customer-dialog", { ref: "deleteCustomerDialog" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "customer-list-header" }, [
      _c("div", { staticClass: "col-5 customer-name" }, [
        _vm._v("\n                客户名称\n            "),
      ]),
      _c("div", { staticClass: "col-6" }, [_vm._v("职位")]),
      _c("div", { staticClass: "col-4 text-center structure-item" }, [
        _vm._v("组织架构"),
      ]),
      _c("div", { staticClass: "col-5 text-center creator-item" }, [
        _vm._v("创建者"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }