var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lbd-center-left-right" },
    [_vm._t("left"), _vm._t("right")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }