var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "traffic-grouping-dialog",
          attrs: {
            title: "业务分组设置",
            visible: _vm.dialogVisible,
            width: "420px",
            "tooltip-effect": "dark",
            "before-close": _vm.cancelHandle,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "grouping-title" }, [
            _c("span", [_vm._v("客户")]),
            _c("span", [_vm._v("业务分组")]),
          ]),
          _c("el-cascader-panel", {
            attrs: {
              options: _vm.options,
              props: {
                children: "customerBusinessGroupItems",
                value: "customerId",
                value: "id",
                label: "name",
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ data }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "label-item" },
                      [
                        _c("text-over-tooltip", {
                          staticClass: "grouping-name",
                          attrs: {
                            refName: "groupingName",
                            content: data.name,
                          },
                        }),
                        _c("span", { staticClass: "edit-grouping" }, [
                          _c("img", {
                            attrs: {
                              src: require("@src/assets/images/customer/editDomain.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editGroupingName(data)
                              },
                            },
                          }),
                          _c("img", {
                            staticClass: "delete-icon",
                            attrs: {
                              src: require("@src/assets/images/program/delete_icon.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteGrouping(data)
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addTrafficGrouping },
                },
                [
                  _c("img", {
                    staticStyle: { "margin-bottom": "2px" },
                    attrs: {
                      src: require("@src/assets/images/customer/addDomin.png"),
                      alt: "",
                    },
                  }),
                  _vm._v("\n                新增分组\n            "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-traffic-grouping-dialog", {
        ref: "addTrafficGroupingDialog",
        attrs: {
          customerList: _vm.customerList,
          isCreateGrouping: _vm.isCreateGrouping,
          currentGrouping: _vm.currentGrouping,
          currentCustomerId: _vm.currentCustomerId,
        },
        on: { "add-success": _vm.getTrafficGrouping },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }