<template>
    <el-dialog
        class="add-traffic-grouping-dialog"
        :title="titleName"
        :visible.sync="addDialogVisible"
        width="492px"
        :before-close="cancelHandle"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="业务分组" prop="name">
                <el-input v-model="form.name" placeholder="输入业务分组名称"></el-input>
            </el-form-item>
            <el-form-item label="客户" prop="customerId">
                <el-select v-model="form.customerId" popper-class="customer-select-dropdown" placeholder="选择客户" filterable :disabled="isCreateGrouping ? false : true">
                    <el-option v-for="item in customerList" :label="item.customerName" :value="item.customerId" :key="item.customerId"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle" :loading="sureLoading">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { customer as customerUrl } from '#/js/config/api.json';

    export default {
        name: "addCustomerDomain",
        components: {

        },
        props: {
            isCreateGrouping: Boolean,
            currentGrouping: Object,
            currentCustomerId: String,
            customerList: Array,
        },
        data() {
            return {
                addDialogVisible: false,
                titleName: '',
                sureLoading: false,
                form: {
                    name: '',
                    customerId: '',
                },
                rules: {
                    name: [
                        { required: true, message: '请输入业务分组名称', trigger: 'blur' },
                        { max: 20, message: '名称不超过20个字符', trigger: 'blur' }
                    ],
                    customerId: [
                        { required: true, message: '请选择客户', trigger: 'change' }
                    ],
                }
            }
        },
        watch: {
            isCreateGrouping(val) {
                console.log(val);
                this.isCreateGrouping = val;
                if(this.isCreateGrouping) {
                    this.titleName = '新增业务分组';
                    this.form.name = '';
                    this.form.customerId = '';
                } else {
                    this.titleName = '编辑业务分组';
                    this.form.name = this.currentGrouping.name;
                    this.form.customerId = this.currentCustomerId;
                }
            },
            // 当前点击的业务分组
            currentGrouping(val) {
                console.log(val);
                this.currentGrouping = val;
                this.form.name = this.currentGrouping.name;
            },
            // 当前点击的业务分组的客户id
            currentCustomerId(val) {
                console.log(val);
                this.form.customerId = this.currentCustomerId;
            }
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            show() {
                this.addDialogVisible = true;
                // console.log(this.currentGrouping);

                if(!this.isCreateGrouping) {
                    this.form.name = this.currentGrouping.name;
                    this.form.customerId = this.currentCustomerId;
                } else {
                    this.form.name = '';
                    this.form.customerId = '';
                }
            },
            reset() {
                this.$refs.form.resetFields();
                this.form = {
                    name: '',
                    customerId: '',
                };
            },
            // 点击取消按钮
            cancelHandle() {
                this.addDialogVisible = false;
                this.reset();
            },
            // 点击确定按钮
            confirmHandle() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.sureLoading = true;
                        let requestParam;
                        if(this.isCreateGrouping) { // 创建
                            requestParam = {
                                url: customerUrl.add_business_group,
                                method: 'POST',
                                baseURL: "LbdOpenApi",
                                data: {
                                    name: this.form.name,
                                    customerId: this.form.customerId
                                }
                            };
                        } else { // 编辑
                            requestParam = {
                                url: customerUrl.update_business_group,
                                method: 'POST',
                                baseURL: "LbdOpenApi",
                                data: {
                                    id: this.currentGrouping.id, // 分组id
                                    name: this.form.name,
                                    customerId: this.form.customerId // 客户id
                                }
                            };
                        }
                        _request(requestParam).then(res => {
                            // console.log(res);
                            this.addDialogVisible = false;
                            this.$emit('add-success');
                        }).finally(() =>{
                            this.sureLoading = false;
                            this.reset();
                        }).catch((err) => {
                            console.log(err);
                        })
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
    .customer-select-dropdown.el-select-dropdown.el-popper {
        max-width: 375px;
    }
</style>

<style lang="scss" scoped>
    .add-traffic-grouping-dialog /deep/ .el-dialog {
        .el-dialog__body {
            padding: 15px 20px 10px;
            .el-select {
                width: 100%;
            }
        }
    }
</style>
