<template>
    <el-dialog
        class="add-customer-domain-dialog"
        :title="titleName"
        :visible.sync="addDialogVisible"
        width="492px"
        :before-close="cancelHandle"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="领域名称" prop="name">
                <el-input v-model="form.name" placeholder="输入领域名称"></el-input>
            </el-form-item>
            <el-form-item label="上级领域" prop="parentId">
                <el-select v-model="form.parentId" popper-class="onelevel-domain-select-dropdown" placeholder="选择上级领域" filterable :disabled="isCreateDomain ? false : true">
                    <el-option label="无" value="-1"></el-option>
                    <el-option v-for="(item,index) in onlyOneLevelDomain" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle" :loading="sureLoading">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { customer as customerUrl } from '#/js/config/api.json';

    export default {
        name: "addCustomerDomain",
        components: {

        },
        props: {
            onlyOneLevelDomain: Array,
            isCreateDomain: Boolean,
            currentDomain: Object,
        },
        data() {
            return {
                addDialogVisible: false,
                titleName: '',
                sureLoading: false,
                form: {
                    name: '',
                    parentId: '',
                },
                rules: {
                    name: [
                        { required: true, message: '请输入领域名称', trigger: 'blur' },
                        { max: 20, message: '名称不超过20个字符', trigger: 'blur' }
                    ],
                    parentId: [
                        { required: true, message: '请选择上级领域', trigger: 'change' }
                    ],
                }
            }
        },
        watch: {
            isCreateDomain(val) {
                // console.log(val);
                this.isCreateDomain = val;
                if(this.isCreateDomain) {
                    this.titleName = '新增客户领域';
                    this.form.name = '';
                    this.form.parentId = '';
                } else {
                    this.titleName = '编辑客户领域';
                    this.form.name = this.currentDomain.label;
                    if(this.currentDomain.parentId) {
                        this.form.parentId = this.currentDomain.parentId;
                    } else {
                        this.form.parentId = '-1';
                    }
                }
            },
            currentDomain(val) {
                // console.log(val);
                this.currentDomain = val;
                this.form.name = this.currentDomain.label;
                if(this.currentDomain.parentId) {
                    this.form.parentId = this.currentDomain.parentId;
                } else {
                    this.form.parentId = '-1';
                }
            }
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            show() {
                this.addDialogVisible = true;
                // console.log(this.onlyOneLevelDomain);
                // console.log(this.currentDomain);
                if(!this.isCreateDomain) {
                    this.form.name = this.currentDomain.label;
                    if(this.currentDomain.parentId) {
                        this.form.parentId = this.currentDomain.parentId;
                    } else {
                        this.form.parentId = '-1';
                    }
                } else {
                    this.form.name = '';
                    this.form.parentId = '';
                }
            },
            reset() {
                this.$refs.form.resetFields();
                this.form = {
                    name: '',
                    parentId: '',
                };
            },
            // 点击取消按钮
            cancelHandle() {
                this.addDialogVisible = false;
                this.reset();
            },
            // 点击确定按钮
            confirmHandle() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.sureLoading = true;
                        let requestParam;
                        if(this.isCreateDomain) { // 创建
                            requestParam = {
                                url: customerUrl.add_customer_domain,
                                method: 'POST',
                                baseURL: "LbdOpenApi",
                                data: {
                                    name: this.form.name,
                                    parentId: this.form.parentId == '-1' ? '' : this.form.parentId
                                }
                            };
                        } else { // 编辑
                            requestParam = {
                                url: customerUrl.update_customer_domain,
                                method: 'POST',
                                baseURL: "LbdOpenApi",
                                data: {
                                    name: this.form.name,
                                    id: this.currentDomain.value
                                }
                            };
                        }
                        _request(requestParam).then(res => {
                            // console.log(res);
                            this.addDialogVisible = false;
                            this.$emit('add-success');
                        }).finally(() =>{
                            this.sureLoading = false;
                            this.reset();
                        }).catch((err) => {
                            console.log(err);
                        })
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
    .onelevel-domain-select-dropdown.el-select-dropdown.el-popper {
        max-width: 375px;
    }
</style>
<style lang="scss" scoped>
    .add-customer-domain-dialog /deep/ .el-dialog {
        .el-dialog__body {
            padding: 15px 20px 10px;
            .el-select {
                width: 100%;
            }
        }
    }
</style>
