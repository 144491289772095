var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "add-traffic-grouping-dialog",
      attrs: {
        title: _vm.titleName,
        visible: _vm.addDialogVisible,
        width: "492px",
        "before-close": _vm.cancelHandle,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.addDialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "业务分组", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入业务分组名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户", prop: "customerId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    "popper-class": "customer-select-dropdown",
                    placeholder: "选择客户",
                    filterable: "",
                    disabled: _vm.isCreateGrouping ? false : true,
                  },
                  model: {
                    value: _vm.form.customerId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "customerId", $$v)
                    },
                    expression: "form.customerId",
                  },
                },
                _vm._l(_vm.customerList, function (item) {
                  return _c("el-option", {
                    key: item.customerId,
                    attrs: { label: item.customerName, value: item.customerId },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.sureLoading },
              on: { click: _vm.confirmHandle },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }