<template>
    <div class="customer-search">
        <div class="customer-option-box">
            <div class="customer-option-btn">
                <span 
                    v-if="isCustomer || isAdministrator"
                    class="add-customer-btn" 
                    @click="addCustomer"
                    >创建客户</span>
                <span 
                    v-if="isRealCustomer"
                    class="add-customer-btn" 
                    @click="addCustomer"
                    >申请创建客户</span>
                <span 
                    v-if="isCustomer || isAdministrator"
                    class="add-customer-btn" 
                    @click="customerApply" 
                    >客户创建审批
                    <i class="apply-count" v-if="count>0">{{count > 99 ? '99+' : count}}</i>
                </span>
                <span 
                    v-if="(isCustomer || isAdministrator) && isCFUser"
                    class="add-customer-btn" 
                    @click="setTrafficGrouping"
                    >业务分组</span>
                <span 
                    v-if="isRealCustomer"
                    class="add-customer-btn" 
                    @click="customerApply"
                    >我的客户创建申请</span>
                <a 
                    class="add-customer-btn" 
                    href="/#/addCustomerRank" 
                    target="_blank"
                    >客户职级</a>
                <span v-if="(isCustomer || isAdministrator) && isCFUser" class="add-customer-btn" @click="setCustomerDomain">客户领域设置</span>
            </div>
        </div>
        <div class="search-area">
            <div class="query-params">
                <div class="search-input">
                    <div class="search-city-btn" :title="selectedCity.text">
                        <el-popover
                            placement="bottom-start"
                            width="629"
                            :visible-arrow="provinceCityConfig.arrowIsShow"
                            v-model="provinceCityConfig.provinceCityIsShow"
                            trigger="click"
                            :offset="0">
                            <span slot="reference" class="search-city-content">
                                {{ selectedCity.text }}
                                <span class="el-icon-arrow-down"></span>
                            </span>

                            <province-city ref="provinceCity" :grid-length="7"
                                            :enableHeader="provinceCityConfig.enableHeader"
                                            :enableFooter="provinceCityConfig.enableFooter"
                                            @confirm="handleCitySelectConfirm"
                                            @cancel="handleCitySelectCancel"></province-city>
                        </el-popover>
                    </div>
                    <div class="search-keywords-input">
                        <el-input
                            :maxlength="100"
                            v-model="firmName"
                            placeholder="输入客户公司全称/简称搜索"
                            @keyup.enter.native="handleSearch"
                        ></el-input>
                        <!-- <input slot="reference" maxlength="100" type="text" v-model="firmName"
                                @keyup.enter="handleSearch"
                                placeholder="输入客户公司全称/简称搜索"> -->
                    </div>
                </div>
                <div class="query-params-item member-wrap">
                    <span class="member-wrap-label">创建者</span>
                    <el-popover
                        popper-class="team-member-popover customer-team-member-popover"
                        placement="bottom-start"
                        width="700"
                        trigger="click"
                        v-model="teamMemberPopoverVisible"
                    >
                        <team-member-select
                            ref="teamSelect"
                            :isAllMembers="true"
                            :isShowDimission="false"
                            :visible="teamMemberPopoverVisible"
                            :selected="selectedUserIds"
                            @item-select-cb="membersSelect"
                        ></team-member-select>
                        <el-input
                            slot="reference"
                            :readonly="true"
                            v-model="creators"
                            placeholder="全部成员"
                            suffix-icon="el-icon-arrow-down"
                        ></el-input>
                    </el-popover>
                    
                        <!-- <team-select
                            ref="employees" 
                            id="employees" 
                            :enable-select-collapse="false" 
                            :multiple="true" 
                            v-on:item-select-cb="employeeSelect" 
                            icon="user-select" 
                            icon-type="ali" 
                            placement="left"
                            :isShowDimission="false"
                            :isAllMembers="true">
                            <template slot="item" slot-scope="{ item }">
                                <span v-text="item.realName"></span><span>{{ item.nickname }}</span>
                            </template>
                        </team-select> -->
                        
                </div>
                <div class="query-params-item date-wrap">
                    <span>创建时间</span>
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        start-placeholder="开始日期"
                        range-separator="-"
                        end-placeholder="结束日期"
                        size="small"
                        :editable="false"
                        align="left"
                        format="yyyy-MM-dd"
                        @change="handleDate"
                        :picker-options="expireTimeOption"
                        popper-class="performance-data-range">
                    >
                    </el-date-picker>
                </div>
            </div>
        </div>
        <div class="search-keywords">
            <!-- <a v-for="customer in moreThanTwentyList" :key="customer.customerId" :href="`/Headhunting/MyCompany.html#/Customer/${customer.customerId}`" target="_blank">
                {{customer.customerName}} {{customer.jobRecruitingCount}}
            </a> -->
            <a v-for="customer in moreThanTwentyList" :key="customer.customerId" :href="`/#/Customer/${customer.customerId}`" target="_blank">
                {{customer.customerName}} {{customer.jobRecruitingCount}}
            </a>
        </div>
        <!-- <div class="more-params clearfix">
            <div class="customer-total">
                共<span> {{totalCount}} </span>家客户
            </div>
            <el-select v-model="sort" class="more-params-select" @change="handleSortChange">
                <el-option label="创建时间排序" :value="0" selected></el-option>
                <el-option label="订单数量排序" :value="1"></el-option>
            </el-select>
        </div> -->

        <customer-create-dialog ref="customerCreateDialog"></customer-create-dialog>
        <customer-domain-dialog ref="customerDomainDialog"></customer-domain-dialog>
        <customer-apply-dialog  ref="customerApplyDialog" @finish-callback="applyFinishCallback"/>

        <traffic-grouping-dialog ref="trafficGroupingDialog"></traffic-grouping-dialog>

        <un-audit-customer-department-dialog ref="unAuditCustomerDepartmentDialog" @refresh-audit-list="getAuditList"></un-audit-customer-department-dialog>

    </div>
</template>
<script>
import moment from 'moment';
import ProvinceCity from '#/component/common/province-city.vue';
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import customerListService from '#/js/service/customerListService.js';
import customerService from '#/js/service/customerService.js';
import CustomerCreateDialog from '../component/customerCreateDialog.vue';
import CustomerDomainDialog from '../component/customerDomainDialog.vue';
import TrafficGroupingDialog from '../component/trafficGroupingDialog';
import CustomerApplyDialog from '../component/customerApplyDialogNew.vue';

import DepartmentService from '#/js/service/departmentService.js';
import UnAuditCustomerDepartmentDialog from '#/component/common/dialog/un-audit-customer-department-dialog.vue';

export default {
    components:{
        ProvinceCity,
        TeamMemberSelect,
        CustomerCreateDialog,
        CustomerDomainDialog,
        TrafficGroupingDialog,
        CustomerApplyDialog,
        UnAuditCustomerDepartmentDialog
    },
    props: {
        totalCount: Number,
        selectedUserIds: {
            type: Array,
            default: function() {
                return []
            }
        },
    },
    data(){
        return{
            auditList: [],
            unAuditCustomerDepartmentNum: 0,

            count: 0,
            sort: 0,
            teamMemberPopoverVisible: false,
            moreThanTwentyList: [],
            provinceCityConfig: {
                enableHeader: true,
                enableFooter: true,
                provinceCityIsShow: false,
                arrowIsShow: false,
            },
            selectedCity: {
                text: '全国',
                id: '',
                isSelect: true,
            },
            firmName: '',
            searchParams: {},
            title: '',
            creators: '全部成员',
            dateRange:'',
            startDate: '',
            endDate: '',
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '昨天',
                    onClick(picker) {
                        const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '今天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '最近7天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近30天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                        const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '上月',
                    onClick(picker) {
                        const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                        const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    computed:{
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isAdministrator(){
            return this.userInfo.isAdministrator;
        },
        isCFUser() {
            return this.userInfo.isCFUser;
        },
        isCustomer() {
            return this.userInfo.privilegeCodeList?.includes('CustomerCreateAudit');
        },
        isRealCustomer() {
            return this.userInfo.privilegeCodeList?.includes('Customer') && !this.isAdministrator;
        },
    },
    created() {
        this.getAuditList();
    },
    mounted(){
        // let yestoday = moment(new Date()).add(-1, 'days');
        // this.dateRange = [yestoday,yestoday];

        customerListService.getCustomerMoreThan20()
            .then(res => {
                this.moreThanTwentyList = res;
            })

        if(this.isCustomer) {
            this.getCustomerApplicationCount(); 
            // 如果打开的链接包含applyList直接打开申请弹出窗口
            if(this.$route.query.hasOwnProperty("applyList") && this.$route.query.applyList == 1) {
                this.$refs.customerApplyDialog.show();
            }
        } else {
            if(this.$route.query.hasOwnProperty("applyList") && this.$route.query.applyList == 2) {
                this.$refs.customerApplyDialog.show();
            }
        }
    },
    methods: {
        getAuditList() {
            DepartmentService.getAuditList().then(res => {
                this.auditList = res || [];
                this.unAuditCustomerDepartmentNum = res.length;
                // console.log(this.unAuditCustomerDepartmentNum);
            }).catch(err => {
                console.log(err);
            })
        },

        // 创建客户
        addCustomer() {
            // this.$refs.customerCreateDialog.show();
            if(!this.isAdministrator && this.unAuditCustomerDepartmentNum > 0) {
                this.$refs.unAuditCustomerDepartmentDialog.show(this.auditList);
            } else {
                this.$refs.customerCreateDialog.show();
            }
        },
        // 客户创建审批
        customerApply() {
            this.$refs.customerApplyDialog.show();
        },
        // 点击客户领域设置
        setCustomerDomain() {
            this.$refs.customerDomainDialog.show();
        },
        // 点击业务分组设置
        setTrafficGrouping() {
            this.$refs.trafficGroupingDialog.show();
        },
        // 申请或者拒绝后的回调
        applyFinishCallback(isAgree) {
            this.getCustomerApplicationCount();

            if (isAgree) {
                this.$emit('updateSearchParams', {});
            }
        },
        getCustomerApplicationCount() {
            customerService.customerApplicationCount().then(res => {
                this.count = res;
            });
        },

        handleSortChange(sort) {
            this.$emit('updateSearchParams', {
                isOrderByCustomerCreated: sort == 0 ? true :false
            })
        },
        membersSelect(userIds, text) {
            this.creators = text || '全部成员';
            if(userIds && userIds.length > 0) {
                this.$emit('updateSearchParams', {
                    memberIds: userIds
                })
            }
            this.teamMemberPopoverVisible = false;
        },
        refresh() {

        },
        handleCitySelectConfirm() {
            if (this.$refs.provinceCity.selectedCity[0]) {
                this.selectedCity = this.$refs.provinceCity.selectedCity[0];
            } else {
                this.selectedCity = {
                    text: '全国',
                    id: '',
                    isSelect: true,
                };
            }

            this.$emit('updateSearchParams', {
                location: this.selectedCity.id
            })

            this.$refs.provinceCity.currentIndex = undefined;
            this.$refs.provinceCity.selectedProvince = '';
            return this.provinceCityConfig.provinceCityIsShow = false;
        },
        handleCitySelectCancel() {
            if (this.$refs.provinceCity.selectedCity[0] && this.$refs.provinceCity.selectedCity[0].id !== this.selectedCity.id) {
                this.$refs.provinceCity.selectCity(this.selectedCity);
            }
            this.$refs.provinceCity.currentIndex = undefined;
            this.$refs.provinceCity.selectedProvince = '';
            return this.provinceCityConfig.provinceCityIsShow = false;
        },
        handleSearch() {
            this.$emit('updateSearchParams', {
                firmName: this.firmName
            })
        },
        handleDate(value){
            if(!value) {
                this.$emit('updateSearchParams', {
                    startDate: '',
                    endDate: ''
                })
            }else {
                this.startDate = moment(value[0]).format('YYYY-MM-DD');
                this.endDate = moment(value[1]).format('YYYY-MM-DD');
                this.$emit('updateSearchParams', {
                    startDate: this.startDate,
                    endDate: this.endDate
                })
            }
        },
    }
};

</script>

<style lang="scss" scoped>
.customer-search {
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;
    .customer-option-box {
        padding: 0 20px;
        height: 60px;
        line-height: 60px;
        text-align: right;
        border-bottom: 1px solid #EEEEEE;
        .customer-option-btn {
            .add-customer-btn {
                cursor: pointer;
                margin-left: 30px;
                display: inline-block;
                width: 140px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                background: #38BC9D;
                border-radius: 4px;
                font-size: 14px;
                color: #FFFFFF;
                &:hover {
                    color: $primary;
                    background-color: #fff;
                    border: 1px solid #38BC9D;
                    .apply-count{
                        background-color: #fff;
                        color: #fff;
                        background-color: #38BC9D;
                    }
                }
                .apply-count{
                    display: inline-block;
                    padding: 2px 5px;
                    box-sizing: border-box;
                    min-width: 16px;
                    // height: 16px;
                    line-height: 14px;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 12px;
                    border-radius: 8px;
                    background-color: #F5A623;
                    color: white;
                }
            }
        }
    }
    .search-area {
        // padding: 24px 12px;
        padding: 20px;
        // background-color: #f8f8f8;
        margin: 0 auto 6px auto;

        .search-input {
            display: flex;
            flex-direction: row;
            // width: 100%;
            width: 32%;
            // height: 36px;
            height: 40px;
            // margin-top: 15px;
            background-color: #fff;
            border: 1px solid #DDDDDD;
            border-radius: 4px;

            .search-city-btn {
                position: relative;
                text-align: center;
                line-height: 36px;
                cursor: pointer;

                /deep/ .el-popover__reference {
                    min-width: 66px;
                    display: block;
                    padding: 0 10px;
                    white-space: nowrap;
                }

                /deep/ .el-icon-arrow-down {
                    margin-left: 4px;
                    font-size: 12px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 24px;
                    background-color: #d3d3d3;
                }
            }

            .search-keywords-input {
                width: 100%;
                vertical-align: top;

                /deep/ .el-input__inner {
                    height: 38px;
                    line-height: 38px;
                    border: 0 none;
                }
            }

            input {
                padding-left: 6px;
                max-width: 499px;
                width: 100%;
                height: 38px;
                line-height: 38px;
                outline: unset;
                border: unset;
                background-color: #fff;
                vertical-align: middle;
            }
        }
        .search-area-contianer {
            display: inline-block;
            vertical-align: top;
        }

        .more-btn {
            position: relative;
            top: 6px;
            margin-left: 10px;
            color: $primary;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
        }

        .query-params {
            display:flex;
            flex-direction: horizontal;
            justify-content: space-between;
            // margin-top: 20px;

            &-item {
                // width: 45%;
                width: 32%;
                height: 40px;
                border: 1px solid #d3d3d3;
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: #fff;

                >span {
                    padding: 0 10px;
                    border-right: 1px solid #d3d3d3;
                    height: 24px;
                    line-height: 24px;
                }
            }

            .member-wrap {
                position: relative;
                // height: 36px;
                height: 40px;

                &-label {
                    position: relative;
                    z-index: 1;
                }

                .el-input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    /deep/ .el-input__inner {
                        padding-left: 70px;
                        height: 38px;
                        line-height: 38px;
                        border: 0 none;
                    }
                    /deep/ .el-input__icon {
                        line-height: 38px;
                    }
                }        
            }

            /deep/ .date-wrap{
                position: relative;

                .el-date-editor--daterange.el-input__inner{
                    width: 240px;
                    border: 0 none;
                }
                .el-date-editor .el-range__icon{
                    display: none;
                }

                .el-date-editor .el-range__close-icon{
                    font-size: 18px;
                }

            }
        }
    }
    .search-keywords {
        padding: 0 20px 20px;
        a {
            display: inline-block;
            background-color: #F5F5F5;
            padding: 5px 15px;
            border-radius: 15px;
            // margin: 10px 10px 7px 0;
            color: #666;

            &:hover {
                color: #fff;
                background-color: $primary;
                cursor: pointer;
            }
        }
    }
}
.search-keywords a {
    display: inline-block;
    background-color: #f1f1f1;
    padding: 5px 15px;
    border-radius: 15px;
    margin: 10px 10px 7px 0;color: #666;

    &:hover {
        color: #fff;
        background-color: $primary;
        cursor: pointer;
    }
}

// .more-params {
//     position: relative;
//     border-bottom: 1px solid #ccc;
//     margin-bottom: 20px;
//     height: 42px;
//     line-height: 42px;

//     .customer-total {
//         position: absolute;
//         top: 0;
//         right: 150px;
//         line-height: 41px;

//         span {
//             font-size: 18px;
//             color: $primary;
//         }
//     }

//     &-select {
//         position: absolute;
//         top: 0;
//         right: 0;
//         width: 130px;

//         /deep/ .el-input__inner {
//             border: 0 none;
//         }
//     }
// }

/deep/ .performance-data-range.el-picker-panel{
    .el-date-range-picker__content.is-left{
        border-right: none;
    }
    .el-date-table{
        .el-date-table__row{
            .normal.disabled{
                div{
                    color: #666;
                }
            }
        }
    }
}
</style>
<style lang="stylus">
.team-member-popover.customer-team-member-popover {
    transform: translateX(0);
}
</style>


