<template>
    <div>
        <el-dialog
            class="customer-domain-dialog"
            title="客户领域设置"
            :visible.sync="dialogVisible"
            width="420px"
            tooltip-effect="dark"
            v-loading="loading"
            :before-close="cancelHandle"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div class="domain-title">
                <span>一级</span>
                <span>二级</span>
            </div>
            <el-cascader-panel :options="options">
                <template slot-scope="{ data }">
                    <div class="label-item">
                        <!-- <span class="domain-name">{{ data.label }}</span> -->
                        <text-over-tooltip refName="domainName" class="domain-name" :content="data.label"></text-over-tooltip>
                        <span class="edit-domain" @click="editDomainName(data)">
                            <img src="@src/assets/images/customer/editDomain.png" alt="">
                        </span>
                    </div>
                </template>
            </el-cascader-panel>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addCustomerDomain">
                    <img src="@src/assets/images/customer/addDomin.png" alt="" style="margin-bottom: 2px;">
                    新增领域
                </el-button>
            </span>
        </el-dialog>
        <add-customer-domain-dialog 
            ref="addCustomerDomainDialog" 
            :onlyOneLevelDomain="onlyOneLevelDomain" 
            :isCreateDomain="isCreateDomain" 
            :currentDomain="currentDomain" 
            @add-success="getCustomerDomain"
        ></add-customer-domain-dialog>
    </div>
</template>

<script>
    import { customer as customerUrl } from '#/js/config/api.json';
    import AddCustomerDomainDialog from './addCustomerDomainDialog.vue';
    import eventBus from '#/js/util/event-bus.js';
    import TextOverTooltip from './textOverTooltip.vue';

    export default {
        name: "customerDomain",
        components: {
            AddCustomerDomainDialog,
            TextOverTooltip
        },
        data() {
            return {
                dialogVisible: false,
                loading: false,
                onlyOneLevelDomain: [], // 一级领域下拉数据
                oneLevelDomainList: [], // 一级领域列表
                secondLevelDomainList: [], // 二级领域列表
                isCreateDomain: true,
                currentDomain: {},
                // options: [
                //     {
                //         value: 'zhinan',
                //         label: '指南',
                //         children: [
                //             {
                //                 value: 'shejiyuanze',
                //                 label: '设计原则',
                //             }, 
                //             {
                //                 value: 'daohang',
                //                 label: '导航',
                //             }
                //         ]
                //     }, 
                //     {
                //         value: 'zujian',
                //         label: '组件',
                //         children: [
                //             {
                //                 value: 'basic',
                //                 label: 'Basic',
                //             }, 
                //             {
                //                 value: 'form',
                //                 label: 'Form',
                //             }, 
                //         ]
                //     }, 
                //     {
                //         value: 'ziyuan',
                //         label: '资源',
                //         children: [
                            
                //         ]
                //     },
                // ]
                options: []
            }
        },
        watch: {
            isCreateDomain(val) {
                // console.log(val);
                this.isCreateDomain = val;
            }
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            show() {
                this.dialogVisible = true;
                this.getCustomerDomain();
            },
            // 点击取消按钮
            cancelHandle() {
                this.dialogVisible = false;
            },
            // 获取领域设置列表
            getCustomerDomain() {
                this.loading = true;
                _request({
                    url: customerUrl.get_customer_domain,
                    method: 'GET',
                    baseURL: "LbdOpenApi",
                    // data: {}
                }).then(res => {
                    // console.log(res);
                    this.onlyOneLevelDomain = res.filter(item => {
                        return item.parentId == '';
                    });
                    // console.log(this.onlyOneLevelDomain);

                    // 过滤一级领域数据
                    this.oneLevelDomainList = res.filter(item => {
                        return item.parentId == '';
                    }).map(v => {
                        return {
                            value: v.id,
                            label: v.name,
                            firmId: v.firmId,
                            parentId: v.parentId,
                            children: []
                        }
                    })
                    // 过滤二级领域数据
                    this.secondLevelDomainList = res.filter(item => {
                        return item.parentId != '';
                    }).map(v => {
                        return {
                            value: v.id,
                            label: v.name,
                            firmId: v.firmId,
                            parentId: v.parentId,
                        }
                    })
                    // console.log(this.oneLevelDomainList);
                    // console.log(this.secondLevelDomainList);

                    // 把对应二级领域的数据放在一级领域的children属性里面
                    this.options = this.oneLevelDomainList.map(one => {
                        // console.log('一级-----------')
                        // console.log(one)
                        this.secondLevelDomainList.some(second => {
                            // console.log('二级-----------')
                            // console.log(second)
                            if(one.value == second.parentId) {
                                // console.log('执行了')
                                one.children.push(second);
                            }
                        })
                        return one;
                    })
                    // console.log(this.options);
                    
                    // 兄弟传值，把this.options传给customerCreateDialog兄弟组件
                    // eventBus.$emit('domainMap',this.options);
                }).finally(() =>{
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                })
            },
            // 点击新增客户领域按钮
            addCustomerDomain() {
                this.isCreateDomain = true;
                if(this.isCreateDomain) {
                    this.$refs.addCustomerDomainDialog.show();
                }
            },
            // 编辑客户领域名称
            editDomainName(data) {
                // console.log(data);
                this.currentDomain = data;
                this.isCreateDomain = false;
                if(!this.isCreateDomain) {
                    this.$refs.addCustomerDomainDialog.show();
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .customer-domain-dialog /deep/ .el-dialog {
        .el-dialog__body {
            padding: 0;
            .domain-title {
                text-align: center;
                color: #666;
                font-size: 14px;
                font-weight: bold;
                span {
                    display: inline-block;
                    width: 210px;
                    height: 50px;
                    line-height: 50px;
                }
            }
            .label-item{
                .domain-name {
                    display: inline-block;
                    max-width: 112px;
                    overflow: hidden; 
                    text-overflow: ellipsis; 
                    vertical-align: middle;
                }
                .edit-domain {
                    display: none;
                    margin-left: 10px;
                    img {
                        margin-bottom: 2px; 
                        cursor: pointer;
                    }
                }
                &:hover{
                    .edit-domain {
                        display: inline-block;
                    }
                }
            }
            
            .el-cascader-panel .el-cascader-menu:nth-child(2) {
                background-color: #F5F5F5;
            }
            .el-cascader-panel.is-bordered {
                border-left: none;
                border-right: none;
                border-radius: 0;
            }
            .el-cascader-menu {
                min-width: 210px;
                border-right: none;
                .el-cascader-menu__wrap {
                    height: 258px;
                }
                .el-cascader-menu__list {
                    padding: 0;
                }
                .el-cascader-node {
                    text-align: center;
                    height: 40px;
                }
                .el-cascader-node:not(.is-disabled):hover {
                    // background-color: #F5F5F5 !important;
                    color: #38BC9D;
                }
                .el-cascader-node:not(.is-disabled):focus {
                    background-color: #F5F5F5 !important;
                    color: #38BC9D;
                    font-weight: normal;
                }
                .el-cascader-node.in-active-path, .el-cascader-node.is-selectable.in-checked-path, .el-cascader-node.is-active {
                    color: #38BC9D;
                    font-weight: normal;
                    background-color: #F5F5F5;
                }
            }
            .el-icon-arrow-right:before {
                content: "";
            }
            .el-icon-check:before {
                content: "";
            }
        }
        .el-dialog__footer {
            padding: 16px 0;
            // border-top: 1px solid #CCCCCC;
            text-align: center;
            .el-button {
                width: 104px;
                height: 30px;
                line-height: 30px;
            }
        }
    }
</style>
