<template>
    <div class="lbd-center-left-right">
        <slot name="left"></slot>
        <slot name="right"></slot>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    }
}
</script>
<style lang="scss">
.lbd-center-left-right {
    display: flex;
    justify-content: space-between;
}
</style>