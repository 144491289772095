var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-search" },
    [
      _c("div", { staticClass: "customer-option-box" }, [
        _c("div", { staticClass: "customer-option-btn" }, [
          _vm.isCustomer || _vm.isAdministrator
            ? _c(
                "span",
                {
                  staticClass: "add-customer-btn",
                  on: { click: _vm.addCustomer },
                },
                [_vm._v("创建客户")]
              )
            : _vm._e(),
          _vm.isRealCustomer
            ? _c(
                "span",
                {
                  staticClass: "add-customer-btn",
                  on: { click: _vm.addCustomer },
                },
                [_vm._v("申请创建客户")]
              )
            : _vm._e(),
          _vm.isCustomer || _vm.isAdministrator
            ? _c(
                "span",
                {
                  staticClass: "add-customer-btn",
                  on: { click: _vm.customerApply },
                },
                [
                  _vm._v("客户创建审批\n                "),
                  _vm.count > 0
                    ? _c("i", { staticClass: "apply-count" }, [
                        _vm._v(_vm._s(_vm.count > 99 ? "99+" : _vm.count)),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          (_vm.isCustomer || _vm.isAdministrator) && _vm.isCFUser
            ? _c(
                "span",
                {
                  staticClass: "add-customer-btn",
                  on: { click: _vm.setTrafficGrouping },
                },
                [_vm._v("业务分组")]
              )
            : _vm._e(),
          _vm.isRealCustomer
            ? _c(
                "span",
                {
                  staticClass: "add-customer-btn",
                  on: { click: _vm.customerApply },
                },
                [_vm._v("我的客户创建申请")]
              )
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "add-customer-btn",
              attrs: { href: "/#/addCustomerRank", target: "_blank" },
            },
            [_vm._v("客户职级")]
          ),
          (_vm.isCustomer || _vm.isAdministrator) && _vm.isCFUser
            ? _c(
                "span",
                {
                  staticClass: "add-customer-btn",
                  on: { click: _vm.setCustomerDomain },
                },
                [_vm._v("客户领域设置")]
              )
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "search-area" }, [
        _c("div", { staticClass: "query-params" }, [
          _c("div", { staticClass: "search-input" }, [
            _c(
              "div",
              {
                staticClass: "search-city-btn",
                attrs: { title: _vm.selectedCity.text },
              },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom-start",
                      width: "629",
                      "visible-arrow": _vm.provinceCityConfig.arrowIsShow,
                      trigger: "click",
                      offset: 0,
                    },
                    model: {
                      value: _vm.provinceCityConfig.provinceCityIsShow,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.provinceCityConfig,
                          "provinceCityIsShow",
                          $$v
                        )
                      },
                      expression: "provinceCityConfig.provinceCityIsShow",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "search-city-content",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.selectedCity.text) +
                            "\n                            "
                        ),
                        _c("span", { staticClass: "el-icon-arrow-down" }),
                      ]
                    ),
                    _c("province-city", {
                      ref: "provinceCity",
                      attrs: {
                        "grid-length": 7,
                        enableHeader: _vm.provinceCityConfig.enableHeader,
                        enableFooter: _vm.provinceCityConfig.enableFooter,
                      },
                      on: {
                        confirm: _vm.handleCitySelectConfirm,
                        cancel: _vm.handleCitySelectCancel,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "search-keywords-input" },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: 100,
                    placeholder: "输入客户公司全称/简称搜索",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleSearch.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.firmName,
                    callback: function ($$v) {
                      _vm.firmName = $$v
                    },
                    expression: "firmName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "query-params-item member-wrap" },
            [
              _c("span", { staticClass: "member-wrap-label" }, [
                _vm._v("创建者"),
              ]),
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class":
                      "team-member-popover customer-team-member-popover",
                    placement: "bottom-start",
                    width: "700",
                    trigger: "click",
                  },
                  model: {
                    value: _vm.teamMemberPopoverVisible,
                    callback: function ($$v) {
                      _vm.teamMemberPopoverVisible = $$v
                    },
                    expression: "teamMemberPopoverVisible",
                  },
                },
                [
                  _c("team-member-select", {
                    ref: "teamSelect",
                    attrs: {
                      isAllMembers: true,
                      isShowDimission: false,
                      visible: _vm.teamMemberPopoverVisible,
                      selected: _vm.selectedUserIds,
                    },
                    on: { "item-select-cb": _vm.membersSelect },
                  }),
                  _c("el-input", {
                    attrs: {
                      slot: "reference",
                      readonly: true,
                      placeholder: "全部成员",
                      "suffix-icon": "el-icon-arrow-down",
                    },
                    slot: "reference",
                    model: {
                      value: _vm.creators,
                      callback: function ($$v) {
                        _vm.creators = $$v
                      },
                      expression: "creators",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "query-params-item date-wrap" },
            [
              _c("span", [_vm._v("创建时间")]),
              _c(
                "el-date-picker",
                {
                  attrs: {
                    type: "daterange",
                    "start-placeholder": "开始日期",
                    "range-separator": "-",
                    "end-placeholder": "结束日期",
                    size: "small",
                    editable: false,
                    align: "left",
                    format: "yyyy-MM-dd",
                    "picker-options": _vm.expireTimeOption,
                    "popper-class": "performance-data-range",
                  },
                  on: { change: _vm.handleDate },
                  model: {
                    value: _vm.dateRange,
                    callback: function ($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange",
                  },
                },
                [_vm._v("\n                >\n                ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "search-keywords" },
        _vm._l(_vm.moreThanTwentyList, function (customer) {
          return _c(
            "a",
            {
              key: customer.customerId,
              attrs: {
                href: `/#/Customer/${customer.customerId}`,
                target: "_blank",
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(customer.customerName) +
                  " " +
                  _vm._s(customer.jobRecruitingCount) +
                  "\n        "
              ),
            ]
          )
        }),
        0
      ),
      _c("customer-create-dialog", { ref: "customerCreateDialog" }),
      _c("customer-domain-dialog", { ref: "customerDomainDialog" }),
      _c("customer-apply-dialog", {
        ref: "customerApplyDialog",
        on: { "finish-callback": _vm.applyFinishCallback },
      }),
      _c("traffic-grouping-dialog", { ref: "trafficGroupingDialog" }),
      _c("un-audit-customer-department-dialog", {
        ref: "unAuditCustomerDepartmentDialog",
        on: { "refresh-audit-list": _vm.getAuditList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }