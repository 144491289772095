<template>
    <div>
        <el-dialog
            class="traffic-grouping-dialog"
            title="业务分组设置"
            :visible.sync="dialogVisible"
            width="420px"
            tooltip-effect="dark"
            v-loading="loading"
            :before-close="cancelHandle"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div class="grouping-title">
                <span>客户</span>
                <span>业务分组</span>
            </div>
            <el-cascader-panel 
                :options="options"
                :props="{
                    children: 'customerBusinessGroupItems',
                    value: 'customerId',
                    value: 'id',
                    label: 'name',
                }"
            >
                <template slot-scope="{ data }">
                    <div class="label-item">
                        <!-- <span class="grouping-name">{{ data.name }}</span> -->
                        <text-over-tooltip refName="groupingName" class="grouping-name" :content="data.name"></text-over-tooltip>
                        <span class="edit-grouping">
                            <img src="@src/assets/images/customer/editDomain.png" alt="" @click="editGroupingName(data)">
                            <img class="delete-icon" src="~@src/assets/images/program/delete_icon.png" alt="" @click="deleteGrouping(data)">
                        </span>
                    </div>
                </template>
            </el-cascader-panel>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addTrafficGrouping">
                    <img src="@src/assets/images/customer/addDomin.png" alt="" style="margin-bottom: 2px;">
                    新增分组
                </el-button>
            </span>
        </el-dialog>
        <add-traffic-grouping-dialog 
            ref="addTrafficGroupingDialog" 
            :customerList="customerList"
            :isCreateGrouping="isCreateGrouping" 
            :currentGrouping="currentGrouping" 
            :currentCustomerId="currentCustomerId" 
            @add-success="getTrafficGrouping"
        ></add-traffic-grouping-dialog>
    </div>
</template>

<script>
    import { customer as customerUrl } from '#/js/config/api.json';
    import customerListService from '#/js/service/customerListService.js';
    import AddTrafficGroupingDialog from './addTrafficGroupingDialog.vue';
    import TextOverTooltip from './textOverTooltip.vue';

    export default {
        name: "trafficGrouping",
        components: {
            AddTrafficGroupingDialog,
            TextOverTooltip
        },
        data() {
            return {
                customerList: [],
                dialogVisible: false,
                loading: false,
                isCreateGrouping: true, // 判断是创建分组还是编辑分组，true为创建，false为编辑
                currentGrouping: {},
                currentCustomerId: '',
                // options: [
                //     {
                //         value: 'zhinan',
                //         label: '指南',
                //         children: [
                //             {
                //                 value: 'shejiyuanze',
                //                 label: '设计原则',
                //             }, 
                //             {
                //                 value: 'daohang',
                //                 label: '导航',
                //             }
                //         ]
                //     }, 
                //     {
                //         value: 'zujian',
                //         label: '组件',
                //         children: [
                //             {
                //                 value: 'basic',
                //                 label: 'Basic',
                //             }, 
                //             {
                //                 value: 'form',
                //                 label: 'Form',
                //             }, 
                //         ]
                //     }, 
                //     {
                //         value: 'ziyuan',
                //         label: '资源',
                //         children: [
                            
                //         ]
                //     },
                // ],
                options: []
            }
        },
        watch: {
            isCreateGrouping(val) {
                // console.log(val);
                this.isCreateGrouping = val;
            }
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        methods: {
            show() {
                this.dialogVisible = true;
                this.getTrafficGrouping();
                this.getCustomerList();
            },
            // 点击取消按钮
            cancelHandle() {
                this.dialogVisible = false;
            },
            // 客户列表
            getCustomerList() {
                this.loading = true;
                customerListService.getMyCompanyCustomers()
                .then(res =>{
                    // console.log(res);
                    this.customerList = res;
                })
                .finally(() =>{
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err)
                })
            },
            // 获取业务分组设置列表
            getTrafficGrouping() {
                this.loading = true;
                _request({
                    url: customerUrl.get_business_group_list,
                    method: 'GET',
                    baseURL: "LbdOpenApi",
                    // data: {}
                }).then(res => {
                    // console.log(res);
                    this.options = res;
                    // this.options = res.map(el=>{
                    //     let tempCustomerBusinessGroupItems = el.customerBusinessGroupItems.map(item=>{
                    //         return {
                    //             value: item.id,
                    //             label: item.name,
                    //         }
                    //     })
                    //     return {
                    //         children: tempCustomerBusinessGroupItems,
                    //         value: el.customerId,
                    //         label: el.name,
                    //     }
                    // });
                    // console.log(this.options);
                }).finally(() =>{
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                })
            },
            // 点击新增业务分组按钮
            addTrafficGrouping() {
                this.isCreateGrouping = true;
                if(this.isCreateGrouping) {
                    this.$refs.addTrafficGroupingDialog.show();
                }
            },
            // 编辑业务分组名称
            editGroupingName(data) {
                // console.log(data);
                this.currentGrouping = data;
                this.isCreateGrouping = false;
                if(!this.isCreateGrouping) {
                    this.$refs.addTrafficGroupingDialog.show();
                }

                this.options.some(item => {
                    // console.log(item);
                    let isFind = item.customerBusinessGroupItems.some(el => {
                        return el.id == data.id;
                    })
                    this.currentCustomerId = item.customerId;
                    return isFind;
                })
                // console.log(this.currentCustomerId);
            },
            // 删除分组业务
            deleteGrouping(data) {
                // console.log(data);
                this.$confirm(`确认是否删除业务分组“<span class="text-name">${data.name}</span>”吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    // type: 'confirm',
                    type: 'warning',
                    customClass: 'delete-grouping-confirm',
                    dangerouslyUseHTMLString: true,
                    // center: true,
                }).then(() => {
                    _request({
                        url: customerUrl.delete_business_group.replace(/%p/g, data.id),
                        method: 'DELETE',
                        baseURL: "LbdOpenApi",
                    })
                    .then(res => {
                        shortTips('业务分组删除成功');
                        this.getTrafficGrouping();
                    }).catch(err => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });  
                });
            }
        }
    }
</script>

<style lang="scss">
    .delete-grouping-confirm {
        // .el-message-box__header .el-message-box__title {
        //     justify-content: start;
        // }
        .el-message-box__content {
            // text-align: center;
            margin: 25px 0 20px;
            .el-message-box__message .text-name {
                color: #38BC9D;
            }
        }
        // .el-message-box__btns {
        //     text-align: right;
        // }
    }
</style>

<style lang="scss" scoped>
    .traffic-grouping-dialog /deep/ .el-dialog {
        .el-dialog__body {
            padding: 0;
            .grouping-title {
                text-align: center;
                color: #666;
                font-size: 14px;
                font-weight: bold;
                span {
                    display: inline-block;
                    width: 210px;
                    height: 50px;
                    line-height: 50px;
                }
            }
            .label-item{
                .grouping-name {
                    display: inline-block;
                    max-width: 112px;
                    overflow: hidden; 
                    text-overflow: ellipsis; 
                    vertical-align: middle;
                }
                .edit-grouping {
                    display: none;
                    margin-left: 10px;
                    img {
                        margin-bottom: 2px; 
                        cursor: pointer;

                        &.delete-icon {
                            margin-left: 10px;
                        }
                    }
                }
                // &:hover{
                //     .edit-grouping {
                //         display: inline-block;
                //     }
                // }
            }
            
            .el-cascader-panel .el-cascader-menu:nth-child(2) {
                background-color: #F5F5F5;
                .label-item{
                    &:hover{
                        .edit-grouping {
                            display: inline-block;
                        }
                    }
                }
            }
            .el-cascader-panel.is-bordered {
                border-left: none;
                border-right: none;
                border-radius: 0;
            }
            .el-cascader-menu {
                min-width: 210px;
                border-right: none;
                .el-cascader-menu__wrap {
                    height: 258px;
                }
                .el-cascader-menu__list {
                    padding: 0;
                }
                .el-cascader-node {
                    text-align: center;
                    height: 40px;
                }
                .el-cascader-node:not(.is-disabled):hover {
                    // background-color: #F5F5F5 !important;
                    color: #38BC9D;
                }
                .el-cascader-node:not(.is-disabled):focus {
                    background-color: #F5F5F5 !important;
                    color: #38BC9D;
                    font-weight: normal;
                }
                .el-cascader-node.in-active-path, .el-cascader-node.is-selectable.in-checked-path, .el-cascader-node.is-active {
                    color: #38BC9D;
                    font-weight: normal;
                    background-color: #F5F5F5;
                }
            }
            .el-icon-arrow-right:before {
                content: "";
            }
            .el-icon-check:before {
                content: "";
            }
        }
        .el-dialog__footer {
            padding: 16px 0;
            // border-top: 1px solid #CCCCCC;
            text-align: center;
            .el-button {
                width: 104px;
                height: 30px;
                line-height: 30px;
            }
        }
    }
</style>
