import { render, staticRenderFns } from "./customerDomainDialog.vue?vue&type=template&id=501da190&scoped=true&"
import script from "./customerDomainDialog.vue?vue&type=script&lang=js&"
export * from "./customerDomainDialog.vue?vue&type=script&lang=js&"
import style0 from "./customerDomainDialog.vue?vue&type=style&index=0&id=501da190&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "501da190",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('501da190')) {
      api.createRecord('501da190', component.options)
    } else {
      api.reload('501da190', component.options)
    }
    module.hot.accept("./customerDomainDialog.vue?vue&type=template&id=501da190&scoped=true&", function () {
      api.rerender('501da190', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/customer-list/component/customerDomainDialog.vue"
export default component.exports