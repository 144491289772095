<template>
    <div class="site-customer-list-right">
        <div class="right-board">
            <div class="card">
                <h2 class="title">本周订单最多</h2>
                <ul class="best-order" v-if="jobCountTopList.length == 0">
                    <li class="order-list">暂无数据</li>
                </ul>
                <ul class="best-order" v-if="jobCountTopList.length > 0">
                    <li class="order-list" v-for="customer in jobCountTopList" :key="customer.customerId">
                        <a target="_blank" :href="`/#/Customer/${customer.customerId}`">
                            <i class="icon-font icon-building-o"></i>
                            <span class="customer-name">{{customer.customerName}}</span>
                            <!-- <span class="customer-location">[{{customer.areaName}}]</span> -->
                            <span class="job-count">{{customer.jobCount}}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="card">
                <h2 class="title">本周推荐最多</h2>
                <ul class="best-order" v-if="recommendCountTopList.length == 0">
                    <li class="order-list">暂无数据</li>
                </ul>
                <ul class="best-recommend" v-if="recommendCountTopList.length > 0">
                    <li class="order-list" v-for="customer in recommendCountTopList" :key="customer.customerId">
                        <a target="_blank" :href="`/#/Customer/${customer.customerId}`">
                            <i class="icon-font icon-building-o"></i>
                            <span class="customer-name">{{customer.customerName}}</span>
                            <!-- <span class="customer-location">[{{customer.areaName}}]</span> -->
                            <span class="job-count">{{customer.recommendationCount}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <customer-create-dialog ref="customerCreateDialog"></customer-create-dialog>
        <customer-domain-dialog ref="customerDomainDialog"></customer-domain-dialog>
        <!-- <customer-apply-dialog 
            ref="customerApplyDialog"
            @finish-callback="applyFinishCallback"
            /> -->

        <traffic-grouping-dialog ref="trafficGroupingDialog"></traffic-grouping-dialog>
        
    </div>
</template>
<script>
import customerListService from '#/js/service/customerListService.js';
import customerService from '#/js/service/customerService.js';
import CustomerCreateDialog from '../component/customerCreateDialog.vue';
// import CustomerApplyDialog from '../component/customerApplyDialogNew.vue';
import CustomerDomainDialog from '../component/customerDomainDialog.vue';
import TrafficGroupingDialog from '../component/trafficGroupingDialog.vue';

export default {
    components: {
        CustomerCreateDialog,
        // CustomerApplyDialog,
        CustomerDomainDialog,
        TrafficGroupingDialog,
    },
    data() {
        return {
            // isCustomerManagePermission: false,
            jobCountTopList: [],
            recommendCountTopList: [],
            count: 0,
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isCustomer() {
            return this.userInfo.privilegeCodeList?.includes('CustomerCreateAudit');
        },
        isAdministrator() {
            return this.userInfo.isAdministrator;
        },
        isInternalControl () {
            return this.$store.state.user.userInfo.roleCodeList?.includes('InternalControl');
        },
        isCFUser() {
            return this.userInfo.isCFUser;
        },
    },
    mounted() {
        // this.isCustomerManagePermission = this.$store.state.user.userInfo.privilegeCodeList.some(el=>{
        //     return el=='CustomerManage';
        // })

        customerListService.getJobCountTop()
            .then(res => {
                this.jobCountTopList = res;
            });
        customerListService.getRecommendCountTop()
            .then(res => {
                this.recommendCountTopList = res;
            });
        // if(this.isCustomer) {
        //     this.getCustomerApplicationCount(); 
        //     // 如果打开的链接包含applyList直接打开申请弹出窗口
        //     if(this.$route.query.hasOwnProperty("applyList") && this.$route.query.applyList == 1) {
        //         this.$refs.customerApplyDialog.show();
        //     }
        // } else {
        //     if(this.$route.query.hasOwnProperty("applyList") && this.$route.query.applyList == 2) {
        //         this.$refs.customerApplyDialog.show();
        //     }
        // }
    },
    methods: {
        getCustomerApplicationCount() {
            customerService.customerApplicationCount().then(res => {
                this.count = res;
            });
        },
        addCustomer() {
            this.$refs.customerCreateDialog.show();
        },
        // customerApply() {
        //     this.$refs.customerApplyDialog.show();
        // },
        // 点击客户领域设置
        setCustomerDomain() {
            this.$refs.customerDomainDialog.show();
        },
        // 点击业务分组设置
        setTrafficGrouping() {
            this.$refs.trafficGroupingDialog.show();
        },
        // 申请或者拒绝后的回调
        applyFinishCallback() {
            this.getCustomerApplicationCount();
        },
    }
}
</script>
<style lang="scss" scoped>
.site-customer-list-right {
    width: 420px;
    margin-left: 20px;
    .right-board {
        .card {
            padding: 20px;
            background-color: #ffffff;
            border-radius: 8px;
            margin-bottom: 20px;

            .add-customer-btn {
                display: block;
                padding: 12px;
                font-size: 16px;
                color: $primary;
                margin-top: 10px;
                text-align: center;
                border: 1px solid $primary;
                cursor: pointer;

                &:hover {
                    color: #fff;
                    background-color: $primary;
                    .apply-count{
                        background-color: #fff;
                        color: #F5A623;
                    }
                }
                .apply-count{
                    font-style: normal;
                    display: inline-block;
                    padding: 2px 5px;
                    box-sizing: border-box;
                    border-radius: 13px;
                    min-width: 26px;
                    // border: 1px solid #F5A623;
                    background-color: #F5A623;
                    color: white;
                }
            }
            .customer-btn {
                display: flex;
                justify-content: space-between;
                a {
                    display: inline-block;
                    width: 140px;
                    padding: 12px;
                    font-size: 16px;
                    color: $primary;
                    margin-top: 10px;
                    text-align: center;
                    border: 1px solid $primary;
                    &:hover {
                        color: #fff;
                        background-color: $primary;
                    }
                }
            }

            .title {
                position: relative;
                font-size: 16px;
                color: #333;
                padding-left: 20px;
                margin-bottom: 15px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 8px;
                    background-color: $primary;
                }
            }

            ul {
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 2px;

                li + li {
                    padding-top: 15px;
                    margin-top: 15px;
                    border-top: 1px solid #eee;
                }

                .customer-name {
                    display: inline-block;
                    margin: 0 10px;
                    max-width: 130px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: top;
                }

                .customer-location {
                    color: #b9b9b9;
                }

                .job-count {
                    float: right;
                }
            }
        }
    }
}
</style>