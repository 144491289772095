<template>
    <div class="customer-list">
        <div class="more-params clearfix">
            <div class="customer-total">
                共<span> {{totalCount}} </span>家客户
            </div>
            <el-select v-model="sort" class="more-params-select" @change="handleSortChange">
                <el-option label="创建时间排序" :value="0" selected></el-option>
                <el-option label="订单数量排序" :value="1"></el-option>
            </el-select>
        </div>
        <ul>
            <li class="customer-list-header">
                <div class="col-5 customer-name">
                    客户名称
                </div>
                <div class="col-6">职位</div>
                <div class="col-4 text-center structure-item">组织架构</div>
                <div class="col-5 text-center creator-item">创建者</div>
            </li>
            <li v-if="list.length == 0" class="customer-list-item-empty">暂无数据</li>
            <template v-if="list.length > 0">
                <li class="customer-list-item" v-for="customer in list" :key="customer.customerId">
                    <!-- <a class="col-5 customer-name" :href="`/Headhunting/MyCompany.html#/Customer/${customer.customerId}`" target="_blank">{{customer.customerName}}</a> -->
                    <a class="col-5 customer-name" :href="`/Headhunting/MyCompany.html#/Customer/${customer.customerId}`" target="_blank">{{customer.customerName}}</a>
                    <div class="col-6 job-col">
                        <span class="job-count">{{customer.jobCount}}</span>
                        <span class="vline"> | </span>
                        <el-tooltip content="招聘中" placement="bottom">
                            <span class="job-recruiting" data-original-title="" title="">
                                <i class="icon-font icon-remen"></i>
                                <span>{{customer.jobRecruitingCount}}</span>
                            </span>
                        </el-tooltip>
                        <el-tooltip content="已暂停" placement="bottom">
                            <span class="job-paused" data-original-title="" title="">
                                <i class="icon-font icon-zanting"></i>
                                <span>{{customer.jobPausedCount}}</span>
                            </span>
                        </el-tooltip>
                        <el-tooltip content="已完成" placement="bottom">
                            <span class="job-finished" data-original-title="" title="">
                                <i class="icon-font icon-finish"></i>
                                <span>{{customer.jobFinishedCount}}</span>
                            </span>
                        </el-tooltip>
                    </div>
                    <div class="col-4 structure-item">
                        <!-- :href="`/Headhunting/MyCompany.html#/Customer/${customer.customerId}/department`" -->
                        <a
                            class="department-link"
                            :href="`/Headhunting/MyCompany.html#/Customer/${customer.customerId}/department`"
                            target="_blank">
                            查看
                        </a>
                    </div>
                    <div class="col-5 creator-info creator-item">
                        <div class="customer-creator">
                            <avatar
                                v-if="customer && customer.avatarId"
                                class="users-avatar"
                                :src="formatAvatarUrl(`/Data/Avatar/${customer.avatarId}`)"
                                :user-id="customer.userId"
                            ></avatar>
                        </div>
                        <div
                            class="creator-info-name"
                            :title="customer.userName">
                            {{customer.userName}}
                        </div>
                    </div>
                    <div class="col-2 text-center show-more" v-if="isCustomerManage">
                        <el-popover
                            popper-class="show-more-popover"
                            placement="bottom"
                            width="184"
                            trigger="hover">
                            <ul>
                                <li @click="mergeToAnotherCustomer(customer)">合并到另一客户</li>
                                <li v-if="customer.canDelete" @click="handleDelete(customer.customerName, customer.customerId)">删除</li>
                            </ul>
                            <i slot="reference" class="icon-font icon-set" data-original-title="" title=""></i>
                        </el-popover>
                    </div>
                </li>
            </template>
        </ul>
        <customer-merge-dialog
            ref="cusMergeDialog"
            @merge-done="$emit('refresh')"
        ></customer-merge-dialog>
        <delete-customer-dialog ref="deleteCustomerDialog"></delete-customer-dialog>
    </div>
</template>
<script>
import Avatar from '#/component/common/avatar';
import DeleteCustomerDialog from './deleteCustomerDialog.vue';
import CustomerMergeDialog from '#/views/customers/component/customer-merge-dialog.vue';
export default {
    components:{
        Avatar,
        CustomerMergeDialog,
        DeleteCustomerDialog
    },
    props: {
        list: Array,
        totalCount: Number,
    },
    data() {
        return {
            sort: 0,
        }
    },
    mounted() {},
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isCustomerManage() {
            return this.userInfo.privilegeCodeList?.includes('CustomerCreateAudit');
        },
    },
    methods: {
        handleSortChange(sort) {
            this.$emit('updateSearchParams', {
                isOrderByCustomerCreated: sort == 0 ? true :false
            })
        },
        formatAvatarUrl(url) {
            return _host.portal + url;
        },
        mergeToAnotherCustomer(customer) {
            this.$refs.cusMergeDialog.show(true, {
                customerId: customer.customerId,
                customerName: customer.customerName
            });
        },
        handleDelete(name, id) {
            this.$refs.deleteCustomerDialog.show({
                name: name,
                id: id
            })
        }
    }
};

</script>

<style lang="scss" scoped>

@media screen and (max-width: 1400px) {
    .customer-name {
        width: 120px;
    }
    .structure-item {
        width: 110px;
        padding-left: 10px;
    }
    .creator-item {
        width: 100px;
    }
    .show-more {
        width: 50px;
    }
}
@media screen and (min-width: 1400px) and (max-width: 1680px) {
    .customer-name {
        width: 120px;
    }
    .structure-item {
        width: 140px;
        padding-left: 10px;
    }
    .creator-item {
        width: 200px;
    }
}


.customer-list {

    .more-params {
        position: relative;
        border-bottom: 1px solid #EEEEEE;
        margin-bottom: 20px;
        height: 60px;
        line-height: 60px;

        .customer-total {
            position: absolute;
            // top: 0;
            right: 150px;
            // line-height: 60px;

            span {
                font-size: 18px;
                color: $primary;
            }
        }

        &-select {
            position: absolute;
            top: 0;
            right: 0;
            width: 130px;

            /deep/ .el-input__inner {
                color: #38BC9D;
                border: 0 none;
            }
            /deep/ .el-select__caret {
                color: #38BC9D;
            }
        }
    }

    >li::before {
        content: '';
        display: table;
    }

    &-header {
        height: 46px;
        line-height: 46px;
        font-size: 16px;
        color: #333;
        text-align: left;
        background-color: #eee;

        >div {
            // padding-left: 10px;
            padding-left: 40px;
        }

        .creator-item {
            padding-left: 10px;
        }

        .col-3.text-center {
            padding: 0;
            text-align: center;
        }
    }

    .job-col {
        display: flex;
        justify-content: space-between;
        line-height: 86px;
        padding-right: 15px;
        padding-left: 30px;
        >span {
            min-width: 40px;
            display: inline-block;
            // text-align: center;
            text-align: left;
            color: #666;

            >.icon-font {
                margin-right: 10px;
            }

            .icon-remen {
                color: $primary;
            }

            &.vline {
                min-width: 10px;
            }
        }
    }
    .job-count{
        font-size: 16px;
        padding-left: 10px;
    }

    .creator-info {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        >div {
            line-height: initial;
        }

        &-name {
            margin-top: 5px;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    &-item {
        font-size: 16px;
        padding: 0;
        border-top: none;
        border-bottom: 1px solid #ececec;
        height: 86px;
        line-height: 86px;
        overflow: hidden;

        .structure-item {
            padding-left: 40px;
        }

        .col-2.creator-info {
            padding-left: 40px;
        }

        .show-more {
            display: none;
        }

        .icon-set {
            color: $primary;
        }

        &:hover {
            background-color: #f6f6f6;

            .show-more {
                display: inline-block;
            }
        }

        > a, .department-link {
            display: block;
            padding: 20px 0 20px 10px;
            height: 86px;
            line-height: 46px;
            font-size: 16px;
            color: #333;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:focus, &:hover {
                color: $primary;
            }
        }

        .customer-name {
            padding-left: 40px;
        }

        .department-link {
            text-align: center;
            font-size: 14px;
            padding-right: 10px;
        }
    }

    &-item-empty {
        text-align: center;
        margin-top: 20px;
    }
}
.show-more-popover >ul li {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;

    &:hover {
        background-color: #f6f6f6;
    }
}
</style>
