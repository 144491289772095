var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-customer-list-right" },
    [
      _c("div", { staticClass: "right-board" }, [
        _c("div", { staticClass: "card" }, [
          _c("h2", { staticClass: "title" }, [_vm._v("本周订单最多")]),
          _vm.jobCountTopList.length == 0
            ? _c("ul", { staticClass: "best-order" }, [
                _c("li", { staticClass: "order-list" }, [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
          _vm.jobCountTopList.length > 0
            ? _c(
                "ul",
                { staticClass: "best-order" },
                _vm._l(_vm.jobCountTopList, function (customer) {
                  return _c(
                    "li",
                    { key: customer.customerId, staticClass: "order-list" },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: `/#/Customer/${customer.customerId}`,
                          },
                        },
                        [
                          _c("i", { staticClass: "icon-font icon-building-o" }),
                          _c("span", { staticClass: "customer-name" }, [
                            _vm._v(_vm._s(customer.customerName)),
                          ]),
                          _c("span", { staticClass: "job-count" }, [
                            _vm._v(_vm._s(customer.jobCount)),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("h2", { staticClass: "title" }, [_vm._v("本周推荐最多")]),
          _vm.recommendCountTopList.length == 0
            ? _c("ul", { staticClass: "best-order" }, [
                _c("li", { staticClass: "order-list" }, [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
          _vm.recommendCountTopList.length > 0
            ? _c(
                "ul",
                { staticClass: "best-recommend" },
                _vm._l(_vm.recommendCountTopList, function (customer) {
                  return _c(
                    "li",
                    { key: customer.customerId, staticClass: "order-list" },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: `/#/Customer/${customer.customerId}`,
                          },
                        },
                        [
                          _c("i", { staticClass: "icon-font icon-building-o" }),
                          _c("span", { staticClass: "customer-name" }, [
                            _vm._v(_vm._s(customer.customerName)),
                          ]),
                          _c("span", { staticClass: "job-count" }, [
                            _vm._v(_vm._s(customer.recommendationCount)),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
      _c("customer-create-dialog", { ref: "customerCreateDialog" }),
      _c("customer-domain-dialog", { ref: "customerDomainDialog" }),
      _c("traffic-grouping-dialog", { ref: "trafficGroupingDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }